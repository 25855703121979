<template>
  <div>
    <div class="page-container mb-2">
      <h4>
        Bộ lọc
      </h4>
      <b-col
        xl="4"
        md="4"
        sm="4"
        lg="4"
        class="pl-0"
      >
        <b-form-group
          label-for="year"
        >
          <label for="basicInput">Kiểu người dùng</label>
          <v-select
            :reduce="name => name.id"
            label="name"
            :options="dataCombobox"
            placeholder="Chọn kiểu người dùng"
            @input="filterUserType"
          >
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </div>
    <div class="page-container-table">
      <vue-good-table
        ref="data-table"
        style-class="vgt-table"
        :select-options="{
          enabled: false
        }"
        :columns="
          columns"
        :rows="dataTable|| []"
        :group-options="{
          enabled: true,
          collapsable: true,
        }"
      >
        <template
          slot="table-header-row"
          slot-scope="props"
          class="m-row"
        >
          <span>
            <span class="max-lines">{{ props.row.title }}</span>
            <feather-icon
              :icon="props.row.vgtIsExpanded===false?'ChevronsDownIcon':'ChevronsUpIcon'"
              class="ml-50 text-primary"
            />
          </span>
          <span>
            <b-dropdown
              variant="link"
              boundary="viewport"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body"
                />
              </template>
              <!-- xem chi tiet  -->
              <b-dropdown-item
                v-if="props.row.position !== 1"
                @click="handleUpParent(props.row)"
              >
                <span>
                  <feather-icon
                    icon="ArrowUpIcon"
                    class="mr-50"
                  />
                  <span>Di chuyển lên trên</span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="handleDownParent(props.row)">
                <span>
                  <feather-icon
                    icon="ArrowDownIcon"
                    class="mr-50"
                  />
                  <span>Di chuyển xuống dưới</span>
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body"
                  />
                </template>
                <!-- xem chi tiet  -->
                <b-dropdown-item
                  v-if="props.row.position !== 1"
                  @click="handleUp(props.row)"
                >
                  <span>
                    <feather-icon
                      icon="ArrowUpIcon"
                      class="mr-50"
                    />
                    <span>Di chuyển lên trên</span>
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="handleDown(props.row)">
                  <span>
                    <feather-icon
                      icon="ArrowDownIcon"
                      class="mr-50"
                    />
                    <span>Di chuyển xuống dưới</span>
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
      </vue-good-table>
      <div class="d-flex mt-2 ml-2">
        <b-button
          variant="primary"
          class="mr-2"
          @click="changeUpMenu"
        >
          Lưu lại
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import {
  BCol,
  BRow,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import VSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BButton,
    VSelect,
    BCol,
    BRow,
    BFormGroup,
  },
  data() {
    return {
      columns: [
        {
          label: 'Tên menu',
          field: 'title',
          sortable: false,
        },
        {
          label: 'Chức Năng',
          field: 'action',
          width: '175px',
        },
      ],
      dataTable: [],
      authotiesId: null,
    }
  },
  computed: {
    ...mapGetters('userType', ['dataCombobox']),
  },
  mounted() {
    this.doFetchUserTypeCombobox()
  },
  created() {
    if (this.authotiesId) {
      this.fetchDataMenu(this.authotiesId)
    }
  },
  methods: {
    ...mapActions('userType', [
      'doFetchUserTypeCombobox',
    ]),

    async fetchDataMenu(userTypeId) {
      const { data } = await axiosApiInstance.get(ConstantsApi.CUSTOM_MENU, {
        params: { authotiesId: userTypeId },
      })
      this.dataTable = data?.data.map(element => ({
        ...element,
        mode: 'span',
        vgtIsExpanded: true,
      }))
    },

    handleUp(val) {
      const indexTable = this.dataTable.findIndex(item => item.id === val.groupId)
      const indexChildren = this.dataTable[indexTable].children.findIndex(item => item.id === val.id)
      const positionChange = this.dataTable[indexTable].children[indexChildren].position
      this.dataTable[indexTable].children[indexChildren].position = this.dataTable[indexTable].children[indexChildren - 1].position
      this.dataTable[indexTable].children[indexChildren - 1].position = positionChange
      this.dataTable[indexTable].children.sort((a, c) => a.position - c.position)
    },

    handleDown(val) {
      const indexTable = this.dataTable.findIndex(item => item.id === val.groupId)
      const indexChildren = this.dataTable[indexTable].children.findIndex(item => item.id === val.id)
      const positionChange = this.dataTable[indexTable].children[indexChildren].position
      this.dataTable[indexTable].children[indexChildren].position = this.dataTable[indexTable].children[indexChildren + 1].position
      this.dataTable[indexTable].children[indexChildren + 1].position = positionChange
      this.dataTable[indexTable].children.sort((a, c) => a.position - c.position)
    },

    handleUpParent(val) {
      const indexTable = this.dataTable.findIndex(item => item.id === val.id)
      const positionChange = this.dataTable[indexTable].position
      this.dataTable[indexTable].position = this.dataTable[indexTable - 1].position
      this.dataTable[indexTable - 1].position = positionChange
      this.dataTable.sort((a, c) => a.position - c.position)
    },

    handleDownParent(val) {
      const indexTable = this.dataTable.findIndex(item => item.id === val.id)
      const positionChange = this.dataTable[indexTable].position
      this.dataTable[indexTable].position = this.dataTable[indexTable + 1].position
      this.dataTable[indexTable + 1].position = positionChange
      this.dataTable.sort((a, c) => a.position - c.position)
    },

    // Gửi menu mới lên
    async changeUpMenu() {
      const models = {
        models: [...this.dataTable],
        userTypeId: this.authotiesId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.POST_CUSTOM_MENU, models).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Sắp xếp thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.fetchDataMenu(this.authotiesId)
        }
      }).catch(err => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(err.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    filterUserType(val) {
      this.authotiesId = val
      this.fetchDataMenu(this.authotiesId)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>
